<template>
  <main id="imprint">
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>
      Souad Bounab<br />
      Taxi Unternehmen<br />
      Odenthaler Stra&szlig;e 66<br />
      51465 Bergisch Gladbach
    </p>

    <h2>Kontakt</h2>
    <p>
      Telefon: 02204 5068620<br />
      E-Mail: kontakt@taxi-rhein-berg.de
    </p>

    <h2>Umsatzsteuer-ID</h2>
    <p>204/5038/2473</p>

    <h2>EU-Streitschlichtung</h2>
    <p>
      Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
        href="https://ec.europa.eu/consumers/odr/" target="_blank"
        rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.
      <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelleteilzunehmen.</p>

    <p>Quelle: <a
        href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
    </p>
  </main>
</template>

<script>
export default {
  name: 'vImprint'
}
</script>

<style lang="stylus" scoped>
#imprint
  color $sec-black
  margin 0 100px
  align-items flex-start

  a
    color $taxi-yellow

  h1
  h2
    color $taxi-yellow

  h1, h2, h3, h4
    font-family OpenSans-Bold

  h1
    margin 15px 0

  h2, h3
    margin 10px 0

  p, span, h4
    margin 5px 0

@media only screen and (max-width: $tablet-screen)
  #imprint
    margin 0 25px
</style>
