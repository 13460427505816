<template>
  <div id="table-container">
    <table>
      <tr v-if="header">
        <th />
        <th v-for="column, index in header" :key="'row-' + index">
          {{ column }}
        </th>
      </tr>

      <tr v-for="row, index in content" :key="'row-' + index">
        <td v-for="column, index in row" :key="'column-' + index">
          {{ column }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ImageCard',

  props: {
    content: Array,
    header: Array
  }
}
</script>

<style lang="stylus" scoped>
#table-container
  width 80%
  height fit-content
  overflow auto
  user-select none

  table
    border-collapse collapse
    margin 20px 0
    width 100%

    tr
      transition .3s

      th
      td
        padding 10px
        transition .4s

      th
        background $taxi-yellow
        color $main-white
        font-family OpenSans-Bold

      td
        &:first-child
          background $taxi-yellow
          color $sec-black

        &:not(:first-child)
          background $sec-black
          color $main-white
          text-align center

      &:nth-child(even)
        filter brightness(0.9)

      &:hover
        td
          background $taxi-yellow
          color $sec-black

@media (pointer:coarse)
  #table-container
    table
      tr
        &:active
          td
            background $taxi-yellow
            color $sec-black

@media only screen and (max-width: $tablet-screen)
  #table-container
    width 95%
</style>
