<template>
  <div class="cards-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardOverview'
}
</script>

<style lang="stylus" scoped>
.cards-list
  width 80%
  display flex
  justify-content center
  align-items center
  flex-wrap wrap
</style>
