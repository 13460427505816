<template>
  <div class="card-overview">
    <img :src="require('@/assets/images/icons/' + imgLink)" :alt="imgAlt">
    <div class="content-container">
      <h3>{{ header }}</h3>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardOverview',

  props: {
    imgLink: String,
    imgAlt: String,
    header: String,
    text: String
  }
}
</script>

<style lang="stylus" scoped>
.card-overview
  background $sec-black
  display flex
  align-items center
  width 30%
  margin 10px 5px
  padding 10px
  color $main-white
  border-radius 15px
  min-width 300px
  min-height 220px

  img
    width 48px
    height 48px
    filter $taxi-yellow-filter
    margin-right 20px

  h3
    color $taxi-yellow
</style>
