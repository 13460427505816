<template>
  <div class="icon-text-card">
    <div class="icon-container">
      <img :src="require('@/assets/images/icons/' + imgLink)" :alt="imgAlt" />
    </div>
    <p class="header">{{ header }}</p>
    <a class="link link-animtaion" :href="textLink" :data-replace="text"><span>{{ text }}</span></a>
  </div>
</template>

<script>
export default {
  name: 'IconTextCard',

  props: {
    imgLink: String,
    imgAlt: String,
    header: String,
    text: String,
    textLink: String
  }
}
</script>

<style lang="stylus" scoped>
.icon-text-card
  display flex
  flex-direction column
  justify-content center
  align-items center
  color $sec-black
  height 100%
  margin 20px

  .icon-container
    width 60px
    height 60px
    border-radius 50%
    background $taxi-yellow
    margin 0 auto
    margin-bottom 10px
    display flex
    justify-content center
    align-items center

    img
      width 48px
      height 48px
      filter invert(1)

  span
    text-align center

  .header
    font-family OpenSans-Bold
    color $taxi-yellow
</style>
