import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Vue3Mq } from "vue3-mq"
import { MqResponsive } from "vue3-mq"

const vue = createApp(App)

vue.use(Vue3Mq, {
  breakpoints: {
    mobile: 0,
    tablet: 769,
    desktop: 1200,
  }
})

vue.component('MqResponsive', MqResponsive)
vue.use(router).mount('#app')
