<template>
  <main id="vehicles-fleet">
    <h1>Fuhrpark</h1>
    <p>
      Taxi Rhein-Berg ist Ihr örtlicher Taxidienst in Bergisch Gladbach.
      Toller Service und professionelle Arbeit sind wichtig. Doch manchmal braucht man einfach das richtige Fahrzeug.<br />
      Möchten Sie eine bequeme, saubere und entspannte Fahrt? Dann kommen Sie einfach zu uns! Werfen Sie einen Blick auf all unsere Fahrzeuge und kontaktieren Sie uns, um zu reservieren.
    </p>
    <SlidesShow :slidesNumber=5 name="vehicles-slide-show">
      <ImageCard
        isActive
        imgLink='vehicles-fleet/mercedes-1.png'
        imgAlt='Mercedes 1 image'
      />
      <ImageCard
        imgLink='vehicles-fleet/mercedes-2.png'
        imgAlt='Mercedes 2 image'
      />
      <ImageCard
        imgLink='vehicles-fleet/vw.png'
        imgAlt='VW image'
      />
      <ImageCard
        imgLink='vehicles-fleet/vw-inside.jpg'
        imgAlt='VW inside image'
      />
      <ImageCard
        imgLink='vehicles-fleet/mercedes-inside.jpg'
        imgAlt='Mercedes inside image'
      />
    </SlidesShow>
  </main>
</template>

<script>
import ImageCard from '@/components/ImageCard.vue'
import SlidesShow from '@/components/SlidesShow.vue'

export default {
  name: 'vVehiclesFleet',

  components: {
    ImageCard,
    SlidesShow
  }
}
</script>

<style lang="stylus" scoped>
#vehicles-fleet
  .vehicles-slide-show
    height 700px
  p
    max-width 80%

  h1, h2
    max-width 90%
    color $taxi-yellow

@media only screen and (max-width: $mobile-screen)
  #vehicles-fleet
    .slides-show
      height 340px
</style>
