<template>
  <div class="slides-show" :class="name">
    <slot></slot>
    <div class="slider-indicators">
      <div
        v-for="index in slidesNumber"
        :key="index"
        :class="'indicator-' + index"
        v-on:click="changeSlide(index)"
      />
    </div>
    <img class="arrow-forward" src="@/assets/images/icons/arrow-forward.svg" alt="Arrow Forward"
    v-on:click="nextSlide()"
    />
    <img class="arrow-backward" src="@/assets/images/icons/arrow-backward.svg" alt="Arrow Backward"
    v-on:click="previousSlide()"
    />
  </div>
</template>

<script>
export default {
  name: 'SlidesShow',

  data() {
    return {
      currentSlide: 1,
      lastSlide: 0,
      xDown: null,
      yDown: null
    }
  },

  props: {
    slidesNumber: {
      default: 1,
      type: Number
    },
    name: {
      type: String
    }
  },

  mounted () {
    document.querySelector(`.${this.name} .indicator-${this.currentSlide}`).classList.add('active')
    document.querySelectorAll(`.${this.name} .slides`)[this.currentSlide - 1].classList.add('active')

    document.querySelector(`.${this.name}`).addEventListener('touchstart', this.handleTouchStart, false)
    document.querySelector(`.${this.name}`).addEventListener('touchmove', this.handleTouchMove, false)
  },

  methods: {
    getTouches (evt) {
      return evt.touches ||
      evt.originalEvent.touches
    },

    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },

    handleTouchMove (evt) {
      if ( ! this.xDown || ! this.yDown ) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
        if ( xDiff > 0 ) {
          this.nextSlide()
        } else {
          this.previousSlide()
        }
      }

      this.xDown = null
      this.yDown = null
    },

    changeSlide (clickedSlide) {
      if (this.currentSlide !== clickedSlide) {
        this.lastSlide = this.currentSlide
        this.currentSlide = clickedSlide

        let currentSlideIndicator = document.querySelector(`.${this.name} .indicator-${this.currentSlide}`)
        let lastSlideIndicator = document.querySelector(`.${this.name} .indicator-${this.lastSlide}`)
        let currentSlideContent = document.querySelectorAll(`.${this.name} .slides`)[this.currentSlide - 1]
        let lastSlideContent = document.querySelectorAll(`.${this.name} .slides`)[this.lastSlide - 1]

        if (currentSlideIndicator) currentSlideIndicator.classList.add('active')
        if (lastSlideIndicator) lastSlideIndicator.classList.remove('active')
        if (currentSlideContent) currentSlideContent.classList.add('active')
        if (lastSlideContent) lastSlideContent.classList.remove('active')
      }
    },

    nextSlide () {
      if (this.currentSlide < this.slidesNumber) {
        this.changeSlide(this.currentSlide + 1)
      } else {
        this.changeSlide(1)
      }
    },

    previousSlide () {
      if (this.currentSlide <= this.slidesNumber && this.currentSlide !== 1) {
        this.changeSlide(this.currentSlide - 1)
      } else {
        this.changeSlide(this.slidesNumber)
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.slides-show
  position relative
  width 100%
  height 400px

  .slider-indicators
    position absolute
    display flex
    flex-direction row
    justify-content center
    align-items center
    bottom 10px
    left 0
    width 100%
    height 15px

    div
      background $main-black
      width 15px
      height 15px
      border-radius 4px
      transition .3s

      &:not(:last-child)
        margin-right 15px

      &.active
        background $taxi-yellow

  .arrow-forward
  .arrow-backward
    width 80px
    height 80px
    position absolute
    bottom calc(50% - 50px)
    filter $taxi-yellow-filter
    cursor pointer
    transition .4s

    &:hover
      transform scale(1.1)

  .arrow-forward
    right 0

  .arrow-backward
    left 0


@media only screen and (max-width: $tablet-screen)
  .arrow-forward
  .arrow-backward
    display none
</style>
