<template>
  <main id="services">
    <!-- Content -->
    <div class="text-block">
      <h1>Unsere Dienstleistungen</h1>

      <p>
        Taxi Rhein-Berg stellt in jeglicher Hinsicht die höchsten Ansprüche an seine Dienstleistungen.
        Wir möchten Ihnen vielfältige und individuelle Dienstleistungen für alle Ihre Bedürfnisse anbieten.
        Schauen Sie sich einige der Dienstleistungen an, die wir anbieten.
      </p>
    </div>

    <!-- Start: Kurierfahrten -->
    <div class="text-block black">
      <h2>Kurierfahrten:</h2>
      <p>Für Sendungen mit höchster Priorität empfehlen wir unseren Kurierdienst. Wir bringen Ihre Güter für Sie schnell und direkt ans Ziel - ganz unkompliziert.</p>
      <p>Unser schneller Lieferservice für Unternehmen und Privatkunden. Ihre Sendung wird sofort und ohne Umwege ausgeliefert oder zu Ihnen gebracht.</p>
    </div>
    <!-- End: Kurierfahrten -->

    <!-- Start: Krankenfahrten oder zur Dialyse -->
    <div class="text-block">
      <h2>Krankenfahrten oder zur Dialyse:</h2>
      <p>Ins Krankenhaus oder zur Dialyse zu müssen, ist für viele Patienten anstrengend und mit unangenehmen Erfahrungen verbunden. Die Fahrten zur und von der Behandlung möchten wir Ihnen so einfach und so bequem wie möglich gestalten. Wir von Taxi Rhein-Berg in Bergisch Gladbach bieten daher Krankenfahrten im Rheinisch Bergischen Kreis an. Unsere Fahrer holen Sie von zu Hause ab und bringen Sie auch wieder nach Hause.</p>
    </div>
    <!-- End: Krankenfahrten oder zur Dialyse -->

    <!-- Start: Taxi zum Krankenhaus -->
    <div class="text-block black">
      <h2>Taxi zum Krankenhaus:</h2>
      <p>Krankenfahrten von der Krankenkasse übernehmen lassen</p>
      <p>Krankenfahrten werden von Ihrem Arzt verordnet und werden mit einer Genehmigung durch die Krankenkasse von dieser anteilig übernommen. Wie hoch dieser Anteil ist und wie viel Sie selber zahlen müssen, ist von der jeweiligen Krankenkasse abhängig. Der gesetzliche Eigenanteil liegt bei 10 % des Fahrpreises, jedoch nicht bei weniger als fünf und nicht mehr als zehn Euro.</p>
      <p>Ob zur Strahlen- oder Chemotherapie, der Dialyse, zur Krankengymnastik oder zu ambulanten Behandlungen: Wir bringen Sie hin.</p>
      <p>Für die Abrechnung mit Ihrer Krankenkasse benötigen wir von Ihnen:</p>
      <ul>
        <li>Den Namen Ihrer Krankenkasse</li>
        <li>Ihren Namen, Ihre Anschrift und Ihr Geburtsdatum</li>
        <li>Gegebenenfalls Nummer des Befreiungsausweises, sofern Sie einen besitzen</li>
        <li>Ihre Unterschrift auf einem Abrechnungsbeleg</li>
        <li>Die ärztliche Verordnung</li>
      </ul>
    </div>
    <!-- End: Taxi zum Krankenhaus -->

    <!-- Start: Großraumtaxi -->
    <div class="text-block">
      <h2>Großraumtaxi:</h2>
      <p>Großraum-Taxis für Familien oder Gruppen.</p>
      <p>Unsere Vans bieten bis zu 6 Fahrgästen Platz. Da es sich um Kleinbusse handelt bleibt dann leider nur noch Platz für Handgepäck.</p>
      <p>Wir können Ihnen auch Fahrzeuge mit bis zu 8 Fahrgastplätzen und ausreichendem Kofferraum anbieten.</p>
      <p>Fragen Sie einfach nach!</p>
    </div>
    <!-- End: Großraumtaxi -->

    <!-- Start: Flughafentransfer -->
    <div class="text-block black">
      <h2>Flughafentransfer:</h2>
      <p>Geht es endlich mal wieder mit dem Flugzeug ins Warme? Oder müssen Sie auf Geschäftsreise? Sparen Sie sich den Stress, mit dem Zug oder selbst zum Flughafen zu fahren und bestellen Sie sich ein Taxi von Taxi Rhein-Berg. Unser Flughafentransfer bringt Sie und Ihr Gepäck bequem ans Ziel. Und auch bei Ihrer Rückkehr stehen wir schon bereit, verstauen Ihren Koffer und bringen Sie bis zur Haustür.</p>
    </div>
    <!-- End: Flughafentransfer -->

    <!-- Start: Schülerfahrten -->
    <div class="text-block">
      <h2>Schülerfahrten:</h2>
      <p>Schülerfahrten in Bergisch Gladbach:</p>
      <p>Sicher und bequem mit dem Taxi zur Schule</p>
      <p>Der Weg zur Schule: Viele Kinder steigen in den Schulbus, fahren mit dem Fahrrad oder werden von ihren Eltern gefahren. Diese Möglichkeiten gibt es jedoch nicht immer. Für einen sicheren und komfortablen Transport zur Schule sind wir die ideale Alternative: Taxi Rhein-Berg sorgt dafür, dass Ihre Kinder pünktlich zur Schule kommen. Wir holen Ihren Nachwuchs zu Hause ab und bringen ihn natürlich auf Wunsch wieder bis zur Haustür.</p>
      <p>Erlaubt es Ihr Beruf mal nicht, Ihre Kleinen selbst zur Schule oder in den Kindergarten zu bringen und fährt der Bus nicht zu den passenden Zeiten? Vertrauen Sie uns Ihren Nachwuchs an und seien Sie sich sicher, dass Ihr Kind unversehrt zur Schule kommt. Bestellen Sie ein Taxi bei uns und fordern Sie eines mit Kindersitzen an. So brauchen Sie sich, um nichts mehr Sorgen zu machen.</p>
    </div>
    <!-- End: Schülerfahrten -->

    <!-- Start: Überführungsfahrten -->
    <div class="text-block black">
      <h2>Überführungsfahrten:</h2>
      <p>Wir bringen Sie und Ihr Auto ans Ziel</p>
      <p>Wenn Sie selbst nicht mehr fahren können, Ihren Wagen aber nicht an einem fremden Ort stehen lassen möchten, fahren wir Sie mit Ihrem Wagen nach Hause.</p>
      <p>Wir sorgen dafür, dass Sie nach einer Feier mit Ihrem eigenen Fahrzeug nach Hause gebracht werden. So kommen Sie sicher nach Hause und Ihren Führerschein dürfen Sie auch behalten</p>
    </div>
    <!-- End: Überführungsfahrten -->

    <!-- Start: Fernfahrten -->
    <div class="text-block">
      <h2>Fernfahrten:</h2>
      <p>Sie benötigen einen Transfer oder Eil-Transport außerhalb der Landesgrenzen?</p>
      <p>Wir befördern Sie oder Ihr Transport-Gut europaweit und zum Pauschalpreis.</p>
      <p>Rufen Sie uns an! Wir unterbreiten Ihnen gerne ein Angebot.</p>
    </div>
    <!-- End: Fernfahrten -->

    <!-- Start: Boten & Besorgungsfahrten -->
    <div class="text-block black">
      <h2>Boten & Besorgungsfahrten:</h2>
      <p>Ihr Abendessen aus dem Restaurant, oder Wocheneinkauf vom Supermarkt, Medikamente aus der Apotheke, die Flasche Wein nachts von der Tankstelle ...</p>
      <p>Wir bringen's eben! Teilen Sie uns einfach mit wo unser Fahrer für Sie einkaufen soll, und geben uns Ihre Telefonnummer. Unser Fahrer wird sich dann unverzüglich mit Ihnen in Verbindung setzen.</p>
    </div>
    <!-- End: Boten & Besorgungsfahrten -->
  </main>
</template>

<script>
export default {
  name: 'vServices'
}
</script>

<style lang="stylus" scoped>
#services
  .text-block
    padding 10px 10vw
    width 80vw

    &.black
      background $sec-black
      color $main-white

    &.yellow
      background $taxi-yellow

    h1
      text-align center

    h1, h2
      color $taxi-yellow

    p
      padding-left 30px

@media only screen and (max-width: $tablet-screen)
  #services
    .text-block
      padding 10px 5vw
      width 90vw
</style>
