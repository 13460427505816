<template>
  <input
    class=".date-time-input-field input"
    type="datetime-local" name="datetime-local"
    :min="getDate('current')"
    :max="getDate('max')"
    :value="getDate('current')"
  >
</template>

<script>
export default {
  name: 'DateTimeInputField',

  data() {
    return {
      currentDate: new Date()
    }
  },

  methods: {
    getDate (time) {
      switch (time) {
        case 'current':
          return `${this.currentDate.getFullYear()}-${((this.currentDate.getMonth() + 1) < 10 ? '0' : '') + (this.currentDate.getMonth() + 1)}-${(this.currentDate.getDate() < 10 ? '0' : '') + this.currentDate.getDate() }T${(this.currentDate.getHours() < 10 ? '0' : '') + this.currentDate.getHours()}:${(this.currentDate.getMinutes() < 10 ? '0' : '') + this.currentDate.getMinutes()}`

        case 'max':
          return `${this.currentDate.getFullYear() + 1}-${((this.currentDate.getMonth() + 1) < 10 ? '0' : '') + (this.currentDate.getMonth() + 1)}-${(this.currentDate.getDate() < 10 ? '0' : '') + this.currentDate.getDate() }T${(this.currentDate.getHours() < 10 ? '0' : '') + this.currentDate.getHours()}:${(this.currentDate.getMinutes() < 10 ? '0' : '') + this.currentDate.getMinutes()}`

        default: return `${this.currentDate.getFullYear()}-${((this.currentDate.getMonth() + 1) < 10 ? '0' : '') + (this.currentDate.getMonth() + 1)}-${(this.currentDate.getDate() < 10 ? '0' : '') + this.currentDate.getDate() }T${(this.currentDate.getHours() < 10 ? '0' : '') + this.currentDate.getHours()}:${(this.currentDate.getMinutes() < 10 ? '0' : '') + this.currentDate.getMinutes()}`
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
#date-time-input-field
  //
</style>
