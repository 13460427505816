<template>
  <!-- Info navigation Desktop/laptop -->
  <MqResponsive id="navi" target="tablet+">
    <div class="info-navi">
      <!-- Left side content -->
      <div class="left-side-content">

        <!-- Logo with link to the home page -->
        <router-link class="logo" to="/">
          <img src="../assets/images/navigation/logo.png" alt="Taxi Rheinberg logo">
        </router-link>
      </div>

      <!-- Right side content -->
      <div class="right-side-content">

        <!-- Phone icon number with link to the phone app -->
        <div class="phone-number">
          <img src="../assets/images/icons/smartphone-icon.svg" alt="Phone icon">
          <a class="link-phone link-animtaion" href="tel:01778812124" data-replace="0177 8812124"><span>0177
              8812124</span></a>
        </div>

        <!-- Phone icon number with link to the phone app -->
        <div class="phone-number">
          <img src="../assets/images/icons/phone-icon.svg" alt="Phone icon">
          <a class="link-phone link-animtaion" href="tel:022045068620" data-replace="02204 5068620"><span>02204
              5068620</span></a>
        </div>

        <!-- Email icon with link to the email app -->
        <div class="email">
          <img src="../assets/images/icons/email-icon.svg" alt="Email icon">
          <a class="link-email link-animtaion" href="mailto:kontakt@taxi-rhein-berg.de"
            data-replace="kontakt@taxi-rhein-berg.de"><span>kontakt@taxi-rhein-berg.de</span></a>
        </div>
      </div>
    </div>

    <!-- Main navigation -->
    <nav ref="mainNavi" class="main-navi">
      <img class="taxi-yellow-boxes" src="../assets/images/navigation/taxi.svg" alt="taxi" />
      <router-link class="link-animtaion" to="/" data-replace="Home"><span>Home</span></router-link>
      <router-link class="link-animtaion" to="/leistungen" data-replace="Leistungen"><span>Leistungen</span></router-link>
      <router-link class="link-animtaion" to="/fuhrpark" data-replace="Fuhrpark"><span>Fuhrpark</span></router-link>
      <router-link class="link-animtaion" to="/preise" data-replace="Preise"><span>Preise</span></router-link>
      <router-link class="link-animtaion" to="/kontakt" data-replace="Kontakt"><span>Kontakt</span></router-link>
    </nav>
  </MqResponsive>

  <!-- Info navigation mobile -->
  <MqResponsive id="navi-mobile" target="mobile">

    <!-- Logo with link to the home page -->
    <div class="main-content">

      <!-- Right side content -->
      <div class="right-side-content">

        <!-- Phone icon number with link to the phone app -->
        <a class="phone-number link-phone" href="tel:022045068620"><img src="../assets/images/icons/phone-icon.svg"
            alt="Phone icon"></a>

        <!-- Phone icon number with link to the phone app -->
        <a class="phone-number link-phone" href="tel:01778812124"><img src="../assets/images/icons/smartphone-icon.svg"
            alt="Phone icon"></a>

        <!-- Email icon with link to the email app -->
        <a class="email link-email" href="mailto:kontakt@taxi-rhein-berg.de"><img
            src="../assets/images/icons/email-icon.svg" alt="Email icon"></a>
      </div>

      <router-link class="logo-center" to="/">
        <img src="../assets/images/navigation/logo.png" alt="Taxi Rheinberg logo">
      </router-link>

      <svg ref="burgerMenuIcon" class="burger-menu-icon" viewBox="0 0 100 100" width="80" v-on:click="toggleNavi">
        <path class="line top" d="m 70,33 h -40 c 0,0 -6,1.368796 -6,8.5 0,7.131204 6,8.5013 6,8.5013 l 20,-0.0013" />
        <path class="line middle" d="m 70,50 h -40" />
        <path class="line bottom"
          d="m 69.575405,67.073826 h -40 c -5.592752,0 -6.873604,-9.348582 1.371031,-9.348582 8.244634,0 19.053564,21.797129 19.053564,12.274756 l 0,-40" />
      </svg>
    </div>

    <!-- Left side content (main navi) -->
    <div ref="burgerMenuContainer" class="burger-menu-container" />
    <nav ref="burgerMenu" class="burger-menu">
      <router-link to="/"><span>Home</span></router-link>
      <router-link to="/leistungen"><span>Leistungen</span></router-link>
      <router-link to="/fuhrpark" data-replace="Fuhrpark"><span>Fuhrpark</span></router-link>
      <router-link to="/preise"><span>Preise</span></router-link>
      <router-link to="/kontakt"><span>Kontakt</span></router-link>
    </nav>
  </MqResponsive>
</template>

<script>
export default {
  name: 'TheMainNavigation',

  mounted() {
    if (this.$refs.burgerMenuContainer) {
      this.$refs.burgerMenuContainer.addEventListener('transitionstart', () => {
        if (!this.$refs.burgerMenuIcon.classList.contains('active')) {
          setTimeout(() => {
            this.$refs.burgerMenuIcon.classList.remove('change-color')
          }, 300)
        } else {
          setTimeout(() => {
            this.$refs.burgerMenu.classList.add('active')
          }, 200)
        }
      })
    }
  },

  watch: {
    $route(to, from) {
      if (to != from && this.$refs.burgerMenuIcon && this.$refs.burgerMenuIcon.classList.contains('active')) this.toggleNavi()
    }
  },

  methods: {
    toggleNavi() {
      if (this.$refs.burgerMenuIcon.classList.contains('active')) {
        this.$refs.burgerMenuIcon.classList.remove('active')
        this.$refs.burgerMenuContainer.classList.remove('show')
        this.$refs.burgerMenu.classList.remove('active')
      } else {
        this.$refs.burgerMenuIcon.classList.add('active')
        this.$refs.burgerMenuContainer.classList.add('show')
        this.$refs.burgerMenuIcon.classList.add('change-color')
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
#navi
  z-index 1
  position sticky
  top 0

  // Main navigation
  .main-navi
    background-color $sec-black
    color $main-white
    font-size $desktop-font-mid
    padding 0 15px
    height 50px

    .taxi-yellow-boxes
      position absolute
      height 50px
      right 0
      bottom 0px

    a
      margin 15px

    .router-link-active
      color $taxi-yellow

  // Info navigation
  .info-navi
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    padding 10px
    font-size $desktop-font-mid
    background-color $main-white
    color $main-black

    .right-side-content
    .left-side-content
      display flex
      flex-direction row
      justify-content center
      align-items center

      .logo
        max-width 110px
        max-height 100%
        padding 5px

        img
         width 100%

      .phone-number
      .email
        margin-right 20px
        display flex
        align-items center

        img
          filter $taxi-yellow-filter
          margin-right 5px
          width 30px

#navi-mobile
  z-index 1
  position sticky
  top 0
  width 100%
  display flex
  flex-direction row
  justify-content center
  align-items center

  .main-content
    width 100%
    padding 25px
    display flex
    flex-direction row
    justify-content center
    align-items center
    box-shadow 0 0 10px 0 #0000005c
    background-color $main-white

    .right-side-content
      position absolute
      right 0
      display flex
      width fit-content

      img
        width 34px
        height 34px
        margin-right 10px
        filter $taxi-yellow-filter

    .logo-center
    .burger-menu-icon
      z-index 1
      position absolute
      display flex
      align-items center

    .logo-center
      max-width 110px
      max-height 100%
      padding 5px
      border-radius 5px
      background #ffffff9e

      img
        width 100%

    .burger-menu-icon
      left 0
      cursor pointer
      transition transform 400ms
      user-select none
      width 50px
      height auto

      .line
        fill none
        transition stroke-dasharray 400ms, stroke-dashoffset 400ms, color 400ms
        stroke $taxi-yellow
        stroke-width 5.5
        stroke-linecap round

      .top
        stroke-dasharray 40 82

      .middle
        stroke-dasharray 40 111

      .bottom
        stroke-dasharray 40 161

      &.active
        transform rotate(45deg)

        .top
          stroke-dasharray 17 82
          stroke-dashoffset -62px

        .middle
          stroke-dashoffset 23px

        .bottom
          stroke-dashoffset -83px

      &.change-color
        .line
          stroke $main-white

  .burger-menu-container
    position fixed
    background-color $taxi-yellow
    top -300px
    left -300px
    width 600px
    height 600px
    border-radius 50%
    transform-origin center
    transition .5s
    transform scale(0)

    &.show
      transform scale(1.5)

  .burger-menu
    font-family OpenSans-Regular
    position fixed
    top 60px
    color $main-white
    left 36px
    height 250px
    flex-direction column
    width 300px
    display flex
    border-radius 0 0 50% 0
    font-size 20px
    justify-content center
    align-items flex-start
    opacity 0
    pointer-events none
    transition .2s

    a
      padding 5px 0
      transition color .3s

    .router-link-active
      color $sec-black

    &.active
      pointer-events all
      opacity 1
</style>
