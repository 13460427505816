<template>
  <main id="prices">
    <h1>Preise</h1>

    <!-- Prices table -->
    <h2>Fahrtkosten berechnen:</h2>
    <TripsCalculator />

    <p><br />Hier finden Sie unsere Taxipreise im Raum Bergisch Gladbach, Köln, Bonn, Leverkusen, Düsseldorf, Frankfurt Flughafen und Hauptbahnhof</p>

    <h2>Tarif Bergisch Gladbach</h2>
    <ContentTable
      class="prices"
      :content="pricesContent.prices"
    />

    <h2>Unsere Preise für Sie</h2>
    <ContentTable
      class="fixed-prices"
      :header="fixedPricesContent['fixed-prices'].destinations"
      :content="fixedPricesContent['fixed-prices'].locations"
    />
  </main>
</template>

<script>
// Components
import ContentTable from '@/components/ContentTable.vue'

// Data for tables
import prices from '@/data/prices.json'
import fixedPrices from '@/data/fixed-prices.json'
import TripsCalculator from '@/components/TripsCalculator.vue'

export default {
  name: 'vPrices',

  components: {
    ContentTable,
    TripsCalculator
  },

  data() {
    return {
      pricesContent: prices,
      fixedPricesContent: fixedPrices
    }
  }
}
</script>

<style lang="stylus" scoped>
#prices
  p
    max-width 80%

  h1, h2
    max-width 90%
    color $taxi-yellow
</style>
