import { createRouter, createWebHistory } from 'vue-router'

// Views
import vHome from '../views/v-home.vue'
import vServices from '../views/v-services'
import vVehiclesFleet from '../views/v-vehiclesFleet'
import vPrices from '../views/v-prices.vue'
import vKontakt from '../views/v-kontakt.vue'
import vImprint from '../views/v-imprint.vue'
import vPrivacyPolicy from '../views/v-privacyPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: vHome,
    meta: {
      title: 'Taxi Bergisch Gladbach',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Taxi bestellen in Bergisch Gladbach, Odenthal, Refrath..'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Taxi bestellen in Bergisch Gladbach, Odenthal, Refrath..'
        }
      ]
    }
  },
  {
    path: '/leistungen',
    name: 'services',
    component: vServices,
    meta: {
      title: 'Taxi Bergisch Gladbach Dienstleistungen',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Kurierfahrten, Krankenfahrten, zum Krankenhaus, Großraumtaxi, Flughafentransfer, Schülerfahrten, Überführungsfahrten, Fernfahrten, Boten, Besorgungsfahrten'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Kurierfahrten, Krankenfahrten, zum Krankenhaus, Großraumtaxi, Flughafentransfer, Schülerfahrten, Überführungsfahrten, Fernfahrten, Boten, Besorgungsfahrten'
        }
      ]
    }
  },
  {
    path: '/fuhrpark',
    name: 'Vehicles-fleet',
    component: vVehiclesFleet,
    meta: {
      title: 'Taxi Bergisch Gladbach Fahrzeuge (Fuhrpark)',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Unsere Fahrzeuge - Taxi Rhein Berg'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Unsere Fahrzeuge - Taxi Rhein Berg'
        }
      ]
    }
  },
  {
    path: '/preise',
    name: 'prices',
    component: vPrices,
    meta: {
      title: 'Taxi Bergisch Gladbach Preise',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Fahrtkosten berechnen, Tarif Bergisch Gladbach, Unsere Preise'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Fahrtkosten berechnen, Tarif Bergisch Gladbach, Unsere Preise'
        }
      ]
    }
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: vKontakt,
    meta: {
      title: 'Taxi Bergisch Gladbach Kontakt',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | E-Mail-Adresse, Telefonnummer, Kontaktformular'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | E-Mail-Adresse, Telefonnummer, Kontaktformular'
        }
      ]
    }
  },
  {
    path: '/impressum',
    name: 'imprint',
    component: vImprint,
    meta: {
      title: 'Taxi Bergisch Gladbach Impressum',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Impressum'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Impressum'
        }
      ]
    }
  },
  {
    path: '/datenschutzerklaerung',
    name: 'privacy-policy',
    component: vPrivacyPolicy,
    meta: {
      title: 'Taxi Bergisch Gladbach Datenschutzerklärung',
      metaTags: [
        {
          name: 'description',
          content: 'Taxi Rhein Berg | Datenschutzerklärung'
        },
        {
          property: 'og:description',
          content: 'Taxi Rhein Berg | Datenschutzerklärung'
        }
      ]
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/',
  },
]


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
})

export default router
