<template>
  <div id="trips-calculator">
    <div class="input-container">
      <label class="input-label" for="datetime-local">Abfahrt</label>
      <DateTimeInputField id="date-time-input" />
    </div>

    <div class="input-container">
      <label class="input-label" for="number">Entfernung (in KM)</label>
      <input class="input" type="number" min="1" id="distance" name="distance" v-model="distance" required>
    </div>

    <div class="others">
      <div class="option">
        <label for="other1">Großraum Taxi? (ab der 5ten Person)</label>
        <input type="checkbox" id="big-taxi" v-model="isBigTaxi" name="other1">
      </div>
      <div class="option">
        <label for="other2">Außerhalb der Stadtgrenze? (Außerhalb Bergisch Gladbach)</label>
        <input type="checkbox" id="outside-city" v-model="isOutsideCity" name="other2">
      </div>
    </div>

    <div class="input-container">
      <p>Ungefährer Preis: <output class="result" for="datetime-local number" /></p>
    </div>
  </div>
</template>

<script>
import DateTimeInputField from './DateTimeInputField.vue'
import hcheck from 'german-holiday'

export default {
  name: 'TripsCalculator',

  components: {
    DateTimeInputField
  },

  data() {
    return {
      price: 0,
      distance: '',
      tripDate: '',
      basePrice: 4,
      tariff1: 3.1,
      tariff1Above5km: 2.8,
      tariff2: 3.2,
      tariff2Above5km: 2.9,
      bigTaxi: 7.7,
      outSideCity: 9.4,
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      isHoliday: false,
      isBigTaxi: false,
      isOutsideCity: false
    }
  },

  watch: {
    distance(value) {
      console.log(value)
      if (value >= 1) {
        this.calculatePrise()
      } else {
        document.querySelector('.result').textContent = ''
        this.distance = ''
      }
    },

    isBigTaxi() {
      if (this.distance !== '') this.calculatePrise()
    },

    isOutsideCity() {
      if (this.distance !== '') this.calculatePrise()
    }
  },

  mounted() {
    this.inputAnimation()
  },

  methods: {
    calculatePrise() {
      this.tripDate = new Date(document.querySelector('#date-time-input').value)
      this.isHoliday = hcheck.holidayCheck(new Date(this.tripDate.getFullYear(), this.tripDate.getMonth(), this.tripDate.getDate()), 'NW') === false ? false : true

      // Tariff 1 everyday exept Sondays and Holidays between 06:00 and 22:00
      if (
        this.tripDate.getDay() !== 0 // Not Sunday
        && this.tripDate.getHours() >= 6 // After 06:00 o'clock
        && this.tripDate.getHours() < 22 // Before 22:00 o'clock
        && !this.isHoliday // Not Holiday
      ) {
        // Check if the distance is above 5km
        if (this.distance > 5) {
          this.price = this.basePrice + (this.tariff1Above5km * this.distance)
        } else {
          this.price = this.basePrice + (this.tariff1 * this.distance)
        }
      }

      // Tariff 2 everyday between 22:00 and 06:00 and the whole day at Sondays and Holidays
      else {
        // Check if the distance is above 5km
        if (this.distance > 5) {
          this.price = this.basePrice + (this.tariff2Above5km * this.distance)
        } else {
          this.price = this.basePrice + (this.tariff2 * this.distance)
        }
      }

      // Other options
      // Big taxi
      if (this.isBigTaxi) {
        this.price = this.price + this.bigTaxi
      }

      // Outside city
      if (this.isOutsideCity) {
        this.price = this.price + this.outSideCity
      }

      if (this.distance !== '') {
        console.log(this.price)
        document.querySelector('.result').textContent = this.price.toFixed(2) + '€'
      } else {
        document.querySelector('.result').textContent = ''
      }
    },

    inputAnimation() {
      document.querySelectorAll('.input').forEach(input => {
        if (input.value !== "" || input.placeholder !== "") {
          input.parentElement.classList.add('focused')
          input.classList.add('filled')
        }

        input.addEventListener('focus', () => {
          input.parentElement.classList.add('focused')
        })

        input.addEventListener('blur', () => {
          let inputValue = input.value

          if (inputValue === "" & input.placeholder === "") {
            input.classList.remove('filled')
            input.parentElement.classList.remove('focused')
          } else {
            input.classList.add('filled')
          }
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
#trips-calculator
  color $taxi-yellow
  background #222
  width 40%
  height fit-content
  display flex
  flex-direction column
  align-items center
  padding 70px 5%
  border-radius 15px

  .form-header
    margin-bottom 25px

  .input-container
    position relative
    width 100%
    margin 15px 0

    &.focused
      .input-label
        transform translateY(-37px)
        color $main-white
        left 0

    .input-label
      position absolute
      left 2.5%
      top 15px
      font-size 15px
      color $main-white
      pointer-events none
      transition all 150ms ease-out

    .input
      color $taxi-yellow
      position relative
      width 90%
      height 50px
      border-radius 15px
      outline 0
      border 0
      background $main-white-transparent1
      padding-left 2.5%
      padding-right 7.5%

      &::placeholder
        color $main-white
        opacity 0.5

      &:focus, &.filled
        background $main-white-transparent2

      &.text-area
        height 160px
        resize none
        width 90%
        padding 5%

  .result
    color $main-white

  .others
    display flex
    justify-content flex-start
    width 100%
    margin 15px 0
    flex-direction column
    color $main-white

    .others-header
      margin-bottom 15px

    .option
      display flex
      padding 0 25px 0 0
      align-items center

      input[type="checkbox"]
        appearance none
        background-color #fff
        width 20px
        height 20px
        border 2px solid $main-white
        border-radius 5px
        transform translateY(-1px)

        display grid
        place-content center

        &::before
          content ""
          width 10px
          height 10px
          transform scale(0)
          transition 120ms transform ease-in-out
          background $taxi-yellow
          border-radius 2px

        &:checked
          &::before
            transform scale(1)

@media only screen and (max-width: $tablet-screen)
  #trips-calculator
    width 90%
</style>
