<template>
  <main id="home">
    <SlidesShow :slidesNumber=3 name="header-slide-show">
      <ImageCard
        imgLink='home/doctor.png'
        imgAlt='doctor image'
        buttonLink='/leistungen'
        buttonText='Dienstleistungen'
        header='unsere Dienstleistungen'
        text='Erfahren Sie mehr über'
        showWall
      />
      <ImageCard
        imgLink='home/airplane-inside.png'
        imgAlt='airplane-inside'
        buttonLink='/preise'
        buttonText='Preise'
        header='unseren Fahrtkosten Rechner & unsere Preise'
        text='Erfahren Sie mehr über'
        showWall
      />
      <ImageCard
        imgLink='home/airport.png'
        imgAlt='airport image'
        buttonLink='/kontakt'
        buttonText='Kontakt'
        header='kontaktieren Sie uns'
        text='Für Vorbestellungen oder andere Anliegen'
        showWall
      />
    </SlidesShow>

    <h1>Unser Ziel</h1>
    <p>Das Ziel von uns ist es, professionelle und günstige Transportdienstleistungen im Umkreis von Bergisch Gladbach zu bieten. Mit unserem umfangreichen und vertrauenswürdigen Service haben Sie mehr Zeit sich auf die wichtigen Dinge zu konzentrieren. Überlassen Sie uns den Rest. Wir kennen Bergisch Gladbach wie unsere Westentasche und garantieren Ihnen, dass Sie sich während einer Fahrt mit uns zurücklehnen, entspannen und sich darauf verlassen können, sicher ans Ziel zu kommen.</p>

    <h2>Warum Sie uns wählen sollen</h2>
    <CardList>
      <CardOverview
        imgLink="security-icon.svg"
        imgAlt="Security icon"
        header="Sicherheit"
        text="
          Bei uns können Sie sich sicher fühlen.
          Unsere geschulten Fahrer und sicheren Fahrzeuge bringen Sie an Ihr Ziel."
      />
      <CardOverview
        imgLink="thumbup-icon.svg"
        imgAlt="Thumbup icon"
        header="Service"
        text="
          Wir möchten Sie mit unserem Service überzeugen und gehen auf Ihre individuellen wünsche ein. Für unsere Kunden gibt es auf Anfrage Erfrischungsgetränke."
      />
      <CardOverview
        imgLink="taxi-icon.svg"
        imgAlt="Taxi icon"
        header="Verschiedene Fahrzeuge"
        text="
          Unsere Taxi Flotte besteht aus Limousinen und Großraumwagen. Bei Anfrage schicken wir das passende Taxi zu Ihnen"
      />
      <CardOverview
        imgLink="payment-icon.svg"
        imgAlt="Payment icon"
        header="Bezahlmöglichkeiten"
        text="
          Möchten Sie bei uns Bar, mit Kreditkarte, über PayPal oder auf Rechnung für Geschäftskunden zahlen ist das kein Problem. Fragen Sie uns einfach an."
      />
      <CardOverview
        imgLink="support-agent-icon.svg"
        imgAlt="Support agent icon"
        header="Kundenservice"
        text="
          Unser Service ist Rund um die Uhr für Sie Verfügbar. Mails werden in der Zeit zwischen 10 - 18 Uhr beantwortet."
      />
      <CardOverview
        imgLink="no-smoke-icon.svg"
        imgAlt="No smoke icon.svg"
        header="Nicht Raucher und Sauber"
        text="
          Wir bemühen uns stets um Ihr Wohlbefinden und halten unsere Fahrzeuge sauber und gepflegt."
      />
    </CardList>
  </main>
</template>

<script>
import SlidesShow from '@/components/SlidesShow.vue'
import ImageCard from '@/components/ImageCard.vue'
import CardOverview from '@/components/CardOverview.vue'
import CardList from '@/components/CardList.vue'

export default {
  name: 'vHome',

  components: {
    SlidesShow,
    ImageCard,
    CardOverview,
    CardList
  }
}
</script>

<style lang="stylus" scoped>
#home
  .slides-show
    height 600px

  p
    width 80%

  h1, h2
    max-width 90%
    color $taxi-yellow

@media only screen and (max-width: $mobile-screen)
  #home
    .slides-show
      height 340px
</style>
