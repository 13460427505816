<template>
  <main id="kontakt">
    <h1>Schreiben Sie uns gerne eine E-Mail oder Rufen Sie uns an</h1>
    <div class="icon-cards-container">
      <IconTextCard
        imgLink="phone-icon.svg"
        imgAlt="Phone icon"
        header="Telefonnummer"
        text="02204 5068620"
        textLink="tel:022045068620"
      />
      <IconTextCard
        imgLink="smartphone-icon.svg"
        imgAlt="Smartphone icon"
        header="Telefonnummer"
        text="0177 8812124"
        textLink="tel:01778812124"
      />
      <IconTextCard
        imgLink="email-icon.svg"
        imgAlt="E-mail icon"
        header="Email"
        text="kontakt@taxi-rhein-berg.de"
        textLink="mailto:kontakt@taxi-rhein-berg.de"
      />
    </div>

    <h1>Nutzen Sie für Vorbestellungen unser Kontakt Formular</h1>
    <ContactForm />
  </main>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import IconTextCard from '@/components/IconTextCard.vue'

export default {
  name: 'vKontakt',

  components: {
    ContactForm,
    IconTextCard
}
}
</script>

<style lang="stylus" scoped>
#kontakt
  p
    max-width 80%

  h1, h2
    max-width 90%
    text-align center

  h1
    font-family OpenSans-Bold
    color $sec-black

  .icon-cards-container
    display flex
    width 50%
    margin-top 25px
    justify-content space-around
    flex-wrap wrap
</style>
